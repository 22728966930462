import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import LoginForm from './LoginForm'
import SubscribeForm from './SubscribeForm'
import Grid from '@material-ui/core/Grid'
import Box from './Box'

const styles = ({ breakpoints }: any) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    wrapper: {
      margin: 40,
      [breakpoints.down('sm')]: {
        margin: 20,
      },
    },
    gridContainer: {
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  afterRedirect: string
}

interface State {}

class Login extends Component<Props, State> {
  render() {
    const { classes, afterRedirect } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Grid container spacing={8} className={classes.gridContainer}>
            <Grid item sm={12} md={6}>
              <Box title="Se connecter">
                <LoginForm afterRedirect={afterRedirect} />
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box title="Créer un compte">
                <SubscribeForm />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default compose<Props, {}>(withStyles(styles))(Login)
