import React from 'react'
import compose from 'recompose/compose'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import * as authActions from '~/ducks/auth'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Router from '~/utils/router'
import Typography from '@material-ui/core/Typography'
import ButtonLink from '~/components/ButtonLink'
import Types from 'Types'
import findLast from 'lodash/findLast'
import { format, parse } from 'url'

const styles = createStyles({
  actions: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& > *': {
      marginBottom: 20,
    },
  },
})

interface PassedProps {
  afterRedirect: string
}

interface Props extends WithStyles<typeof styles> {
  authenticate: (email: string, password: string) => any
  redirectToLastPage: {
    href: string | object
    as: string
  }
}

interface State {
  email: string
  password: string
  error?: any
}

class LoginForm extends React.Component<Props & PassedProps, State> {
  state = {
    email: '',
    password: '',
    error: null,
  }

  handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.setState({ error: null })
    const { email, password } = this.state
    this.props
      .authenticate(email, password)
      .then(() => {
        if (this.props.afterRedirect) {
          Router.push(this.props.afterRedirect)
        } else if (this.props.redirectToLastPage) {
          let { href, as } = this.props.redirectToLastPage
          Router.push(href, as)
        }
      })
      .catch((err: any) => {
        let error = err.non_field_errors && err.non_field_errors[0]
        if (!error) error = err.password && err.password[0]
        if (!error) error = err.email && err.email[0]
        if (!error) error = err.detail
        if (!error) error = JSON.stringify(err)
        this.setState({ error })
      })
  }

  render() {
    const { classes } = this.props
    const { error } = this.state
    return (
      <form onSubmit={this.handleLogin}>
        <TextField
          type="text"
          name="email"
          label="Email"
          autoFocus
          required
          fullWidth
          onChange={(e) => this.setState({ email: e.target.value })}
        />
        <TextField
          type="password"
          name="password"
          label="Mot de passe"
          required
          fullWidth
          onChange={(e) => this.setState({ password: e.target.value })}
        />
        {error && <Typography color="error">{error}</Typography>}
        <div className={classes.actions}>
          {/*
          // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877 */}
          <Button
            variant="outlined"
            component={ButtonLink}
            href="/reset-password"
          >
            Mot de passe oublié
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Se connecter
          </Button>
        </div>
      </form>
    )
  }
}

export default compose<Props & PassedProps, any>(
  withStyles(styles),
  connect(
    (state: Types.RootState) => ({
      redirectToLastPage: findLast(
        state.routes.history,
        (route) =>
          [
            '/login',
            '/reset-password-confirm',
            '/reset-password',
            '/activate',
          ].indexOf(parse(format(route.href)).pathname!) === -1
      ) || { href: '/' },
    }),
    (dispatch: any) => ({
      authenticate: (email: string, password: string) =>
        dispatch(authActions.authenticate({ email, password })),
    })
  )
)(LoginForm)
