import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
  Field,
  reduxForm,
  InjectedFormProps,
  SubmissionError,
} from 'redux-form'
import TextField from '~/components/redux-form-fields/TextField'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import { connect } from 'react-redux'
import * as toastActions from '~/ducks/toast'

const styles = createStyles({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& > *': {
      marginBottom: 20,
    },
  },
})

interface Props extends WithStyles<typeof styles>, InjectedFormProps {
  toast: typeof toastActions.open
}
interface State {}

const ADD_USER = gql`
  mutation createUser($email: String!, $slug: String!, $password: String!) {
    createUser(input: { email: $email, slug: $slug, password: $password }) {
      email
      slug
      id
      errors {
        field
        messages
      }
    }
  }
`

interface ErrorForm {
  field: string
  messages: Array<string>
}

interface Data {
  createUser: {
    email: 'string'
    slug: 'string'
    id: 'string'
    errors?: Array<ErrorForm>
  }
}

interface Variables {
  email: 'string'
  slug: 'string'
  password: 'string'
}

class SubscribeForm extends Component<Props, State> {
  render() {
    const { classes, handleSubmit, submitting, reset, toast } = this.props
    return (
      <Mutation<Data, Variables> mutation={ADD_USER}>
        {(createUser) => (
          <form
            onSubmit={handleSubmit((input: any) => {
              return createUser({ variables: input }).then(
                ({
                  data: {
                    createUser: { errors },
                  },
                }: any) => {
                  if (errors) {
                    const errorMessages = {
                      _error: 'Subscription failed',
                    } as any
                    errors.forEach(({ field, messages }: ErrorForm) => {
                      errorMessages[field] = messages.join(' ')
                    })
                    throw new SubmissionError(errorMessages)
                  }
                  toast({
                    message:
                      "Compte créé ! Vérifiez mainentant vos emails pour l'activer",
                    variant: 'success',
                  })
                  reset()
                }
              )
            })}
          >
            <div className={classes.formContent}>
              <Field
                name="slug"
                type="text"
                pattern="[a-zA-Z0-9-]+"
                component={TextField}
                label="Nom d'utilisateur"
                required
              />
              <Field
                name="email"
                type="email"
                component={TextField}
                label="Email"
                required
              />
              <Field
                name="password"
                type="password"
                component={TextField}
                label="Mot de passe"
                required
              />
              <div className={classes.actions}>
                <Button
                  variant="contained"
                  disabled={submitting}
                  type="submit"
                  color="primary"
                  size="large"
                >
                  S'inscrire
                </Button>
              </div>
            </div>
          </form>
        )}
      </Mutation>
    )
  }
}

export default compose<Props, {}>(
  withStyles(styles),
  connect(() => ({}), { toast: toastActions.open }),
  reduxForm({
    form: 'subscribe',
  })
)(SubscribeForm)
