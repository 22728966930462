import React from 'react'
import Login from '~/components/Login'
import Layout from '~/components/Layout'

const LoginPage = ({ after }) => {
  return (
    <Layout>
      <Login afterRedirect={after} />
    </Layout>
  )
}

LoginPage.getInitialProps = async (ctx) => {
  return {
    after: ctx.query.after,
  }
}
export default LoginPage
